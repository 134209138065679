import { useStaticQuery, graphql } from "gatsby"

export const useTourRegionsWithCategories1 = () => {
  const {
    tenTours: {
      tourRegions: { nodes: tourRegions },
    },
  } = useStaticQuery(graphql`
    query TourRegionsWithCategoriesQuery1 {
      tenTours {
        tourRegions(first: 300, where: { orderby: NAME }, after: null) {
          nodes {
            id: databaseId
            name
            slug
            uri
            parentId: parentDatabaseId
            tourCategories(first: 1000) {
              nodes {
                id: databaseId
              }
            }
          }
        }
      }
    }
  `)
  return tourRegions
}

import React from "react"
import PropTypes from "prop-types"
import GTM from "@src/services/gtm"
import ToursLayout from "@src/layouts/tours"
import { ImagePropTypes } from "@src/components/core-image"
import { PageSeoPropTypes } from "@src/components/page-helmet"
import { TourDetailTilePropTypes } from "@src/components/content-tour-detail-tile"
import { orderByField } from "@src/utils/sorting"
import { useTourRegionsWithCategories } from "@src/hooks/useTourRegionsWithCategories"

const PageTemplate = ({
  pageContext: {
    data: { tenTours: data },
    skipForBottomSeo,
    replaceURLs,
    ...pageContext
  },
}) => {
  const {
    tourRegion: {
      name: regionName,
      slug: regionSlug,
      uri: regionUri,
      seo,
      bottomSeo,
      seoTextTop,
      faq,
      seoTextBottomNew,
      pageSeo,
    },
    tourCategory: { name: categoryName, slug: categorySlug },
    tours: { nodes: tourData },
  } = data
  const TOURS_LAYOUT_TOP_TEXT_DEFAULT = `Explore incredible ${categoryName.toLowerCase()} tours in ${regionName} below.`

  const {
    basePath: regionCategoryUri,
    pageNumber,
    pageCount,
    postsPerPage: toursPerPage,
    postsTotal: totalTours,
    postIds: toursOrder,
    regionId,
    categoryId,
  } = pageContext
  const pageName = `${regionName} ${categoryName} Tours`

  const { filteredTourRegionsWithCategory } =
    useTourRegionsWithCategories(categoryId)
  const textTop =
    pageNumber > 1 ? null : seoTextTop || TOURS_LAYOUT_TOP_TEXT_DEFAULT
  GTM.dataLayerPushPageInfo({
    template: "tour-region-category",
  })
  return (
    <ToursLayout
      regionName={regionName}
      regionSlug={regionSlug}
      regionUri={regionUri}
      categoryName={categoryName}
      categorySlug={categorySlug}
      pageName={pageName}
      pageSeo={{
        ...seo,
        metaRobotsNoindex: "index",
        title: pageSeo ? pageSeo.title : `Best ${pageName}`,
        metaDesc: pageSeo
          ? pageSeo.metadesc
          : `Explore self-guided, guided, and custom ${categoryName} tours in ${regionName}. Book your ${categoryName} vacation in ${regionName} today!`,
      }}
      headerText={textTop}
      tourData={tourData?.sort(orderByField("id", toursOrder))}
      toursParentUri={regionCategoryUri}
      toursPageNumber={pageNumber}
      toursPageCount={pageCount}
      toursPerPage={toursPerPage}
      totalTours={totalTours}
      regionId={regionId}
      categoryId={categoryId}
      bottomSeoData={bottomSeo}
      skipForBottomSeo={skipForBottomSeo}
      replaceURLs={replaceURLs}
      tourRegionCategoriesList={filteredTourRegionsWithCategory}
      faqs={faq}
      seoTextBottomNew={seoTextBottomNew}
    />
  )
}

PageTemplate.propTypes = {
  pageContext: PropTypes.shape({
    basePath: PropTypes.string,
    pageNumber: PropTypes.number,
    pageCount: PropTypes.number,
    postsPerPage: PropTypes.number,
    postsTotal: PropTypes.number,
    postIds: PropTypes.arrayOf(PropTypes.number),
    regionId: PropTypes.number,
    categoryId: PropTypes.number,
    skipForBottomSeo: PropTypes.arrayOf(PropTypes.string),
    replaceURLs: PropTypes.object,
    data: PropTypes.shape({
      tenTours: PropTypes.shape({
        tourRegion: PropTypes.shape({
          name: PropTypes.string,
          uri: PropTypes.string,
          seo: PropTypes.shape({ ...PageSeoPropTypes }),
          headerImage: PropTypes.shape({
            feature: PropTypes.shape({ ...ImagePropTypes }),
          }),
        }),
        tourCategory: PropTypes.shape({
          name: PropTypes.string,
          slug: PropTypes.string,
        }),
        tours: PropTypes.shape({
          nodes: PropTypes.arrayOf(
            PropTypes.shape({
              ...TourDetailTilePropTypes,
            })
          ),
        }),
      }),
    }),
  }),
}

export default PageTemplate

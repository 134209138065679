/*
useTourRegionsWithCategories
- goal:
  - build-time querying for tourRegionsWithCategories

- challenges:
  - heavy region-category cross-reference lookups cause server to timeout consistently during builds
  - limitation of gatsby static queries not allowing dynamic cursors:
    * https://www.gatsbyjs.com/docs/how-to/querying-data/use-static-query/#known-limitations

- cautions:
  - watch out for hard-coded cursors in graphql "after" args
  - watch out for orderby: NAME
    - cursor offsets depend on it
    - WPGraphQL does not seem to support "after" arg with orderby: TERM_ID

- implementation details:
  - loading 300 at a time
    - servers should be able to handle this number reliably
  - cursors hand-picked at ~250 regions apart (from production server, sorted by NAME)
    - room to grow of ~50 regions between cursors before adjustments needed
    - ideally choose cursors for regions that are unlikely to be removed

- alternate solution:
  - load tourRegionsWithCategories gatsby-node and pass in pageContext + prop drilling
*/
import { useMemo } from "react"
import { useTourRegionsWithCategories1 } from "./useTourRegionsWithCategories1"
import { useTourRegionsWithCategories2 } from "./useTourRegionsWithCategories2"
import { useTourRegionsWithCategories3 } from "./useTourRegionsWithCategories3"
import { useTourContinents } from "../useTourContinents"
import { mapListToTree } from "@src/utils/trees"

const concatSkipOverlappingIds = listParts =>
  listParts.reduce((result, listPart) => {
    if (!listPart || !listPart.length) {
      return result
    }
    if (result && result.length) {
      const lastId = result[result.length - 1]
        ? result[result.length - 1].id
        : null
      if (lastId) {
        const skipToIndex = listPart.findIndex(check => check.id === lastId) + 1
        return result.concat(listPart.slice(skipToIndex))
      } else {
        return result.concat(listPart)
      }
    }
    return [...listPart]
  }, [])

export const useTourRegionsWithCategories = (categoryId, regionSlug) => {
  const tourRegionsWithCategories1 = useTourRegionsWithCategories1()
  const tourRegionsWithCategories2 = useTourRegionsWithCategories2()
  const tourRegionsWithCategories3 = useTourRegionsWithCategories3()
  const tourContinents = useTourContinents()
  const tourRegionsWithCategories = useMemo(
    () =>
      concatSkipOverlappingIds([
        tourRegionsWithCategories1,
        tourRegionsWithCategories2,
        tourRegionsWithCategories3,
      ]),
    [
      tourRegionsWithCategories1,
      tourRegionsWithCategories2,
      tourRegionsWithCategories3,
    ]
  )
  const filteredTourRegionsWithCategory = useMemo(() => {
    if (!categoryId) {
      return
    }

    return tourRegionsWithCategories
      .map(({ tourCategories, ...region }) => ({
        tourCategories:
          tourCategories && tourCategories.nodes.map(item => item.id),
        ...region,
      }))
      .filter(
        region =>
          region.tourCategories && region.tourCategories.includes(categoryId)
      )
  }, [tourRegionsWithCategories, categoryId])

  const filteredTourRegionWithChildren = useMemo(() => {
    if (!regionSlug) {
      return
    }

    const region = tourRegionsWithCategories.find(
      region => region.slug === regionSlug
    )
    const regionId = region && region.id
    const children = mapListToTree(tourRegionsWithCategories, regionId)
    if (region && children && children.length) {
      region.items = children
    }
    return region
  }, [tourRegionsWithCategories, regionSlug])

  const countriesWithCategory = useMemo(() => {
    if (!categoryId) {
      return
    }
    const continentIds = tourContinents.map(continent => continent.id)
    const countries = tourRegionsWithCategories
      .filter(region => continentIds.includes(region.parentId))
      .map(({ tourCategories, ...region }) => ({
        tourCategories:
          tourCategories && tourCategories.nodes.map(item => item.id),
        ...region,
      }))

    return countries.filter(country =>
      country.tourCategories.includes(categoryId)
    )
  }, [tourRegionsWithCategories, tourContinents, categoryId])
  return {
    tourRegionsWithCategories,
    filteredTourRegionsWithCategory,
    filteredTourRegionWithChildren,
    countriesWithCategory,
  }
}

import { useStaticQuery, graphql } from "gatsby"

export const useTourRegionsWithCategories3 = () => {
  const {
    tenTours: {
      tourRegions: { nodes: tourRegions },
    },
  } = useStaticQuery(graphql`
    query TourRegionsWithCategoriesQuery3 {
      tenTours {
        tourRegions(
          first: 300
          where: { orderby: NAME }
          after: "YXJyYXljb25uZWN0aW9uOjEyMA=="
        ) {
          nodes {
            id: databaseId
            name
            slug
            uri
            parentId: parentDatabaseId
            tourCategories(first: 1000) {
              nodes {
                id: databaseId
              }
            }
          }
        }
      }
    }
  `)
  return tourRegions
}
